<template>
  <b-card>
    <b-modal
      id="coa-mapping-modal"
      size="lg"
      title="Student Account COA Mapping"
      ok-title="Save"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @ok.prevent="saveCoaMapping"
    >
      <b-form @submit.prevent>
        <div>
          <!-- income  -->
          <b-row>
            <b-col
              cols="11"
              md="11"
              class="mb-md-0 mb-2"
            >
              <b-form-group
                label="Income (Student Fee)"
                invalid-feedback="Income (Student Fee) is required"
                :state="incomeState"
                label-cols-md="4"
              >
                <v-select
                  v-model="coaMapping.income"
                  label="gl_name"
                  placeholder="Select Income (Student Fee)"
                  :options="incomeOptions"
                  :reduce="val => val.id"
                  :state="incomeState"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="11"
              md="11"
              class="mb-md-0 mb-2"
            >
              <b-form-group
                label="Asset (Receivable)"
                invalid-feedback="Asset (Receivable) is required"
                :state="assetState"
                label-cols-md="4"
              >
                <v-select
                  v-model="coaMapping.asset"
                  label="gl_name"
                  placeholder="Select Asset (Receivable)"
                  :options="assetOptions"
                  :reduce="val => val.id"
                  :state="assetState"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="11"
              md="11"
              class="mb-md-0 mb-2"
            >
              <b-form-group
                label="Expense (Concession)"
                invalid-feedback="Expense (Concession) is required"
                :state="expenseState"
                label-cols-md="4"
              >
                <v-select
                  v-model="coaMapping.concessionExpense"
                  label="gl_name"
                  placeholder="Select Expense (Concession)"
                  :options="expenseOptions"
                  :reduce="val => val.id"
                  :state="expenseState"
                  multiple
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="11"
              md="11"
              class="mb-md-0 mb-2"
            >
              <b-form-group
                label="Default Expense"
                invalid-feedback="Expense (Concession) is required"
                :state="expenseState"
                label-cols-md="4"
              >
                <v-select
                  v-model="coaMapping.defaultExpense"
                  label="gl_name"
                  placeholder="Select Default Expense"
                  :options="expenseOptions"
                  :reduce="val => val.id"
                  :state="expenseState"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-form>

    </b-modal>
    <div class="d-flex justify-content-between">
      <p class="card-title ">
        COA Mapping
      </p>
      <b-link
        variant="outline-info mx-2"
        type="button"
        @click="onActivityLog"
      >
        Activity log
      </b-link>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-between">
      <b-form-group>
        <b-button
          v-b-modal.coa-modal
          variant="outline-primary"
          @click.prevent="coaMappingModal"
        >
          Add Student Account COA Mapping
        </b-button>
      </b-form-group>
      <!-- <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group> -->
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      theme="my-theme"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span class="d-flex">
            <b-button
              class="mr-1 px-1"
              variant="outline-warning"
              @click="editRow(props.row)"
            >Edit</b-button>
            <!-- <b-button
              class="px-1"
              variant="outline-danger"
              @click="deleteRow(props.row)"
            >Delete</b-button> -->
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','20','30','40','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div class="d-flex">
            <div class="mr-2">
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
            <div
              slot="table-actions"
              class="mt-1"
            >
              <b-button
                class="mr-1 px-1"
                variant="outline-primary"
                @click="refreshGrid()"
              >
                <RefreshCwIcon />
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </vue-good-table>

    <!--    Activity Model-->
    <activity-modal
      v-if="activityModal"
      ref="activityModal"
      :tag="tag"
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BPagination,
  BForm,
  BFormGroup,
  BFormSelect,
  BRow,
  BCol,
  VBModal,
  BButton,
  BLink,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import vSelect from 'vue-select'
import store from '@/store/index'
import { RefreshCwIcon } from 'vue-feather-icons'
import FemsToastrService from '@/@service/utils/FemsToastr.service'
import CoaMappingService from '@/@service/api/accounting/CoaMapping.service'
import ActivityModal from '../audit/ActivityModal.vue'

export default {
  components: {
    BCard,
    VueGoodTable,
    BPagination,
    BForm,
    BFormGroup,
    BFormSelect,
    BRow,
    BCol,
    vSelect,
    BButton,
    RefreshCwIcon,
    BLink,
    ActivityModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {

      // b - modal
      coaMapping: {
        income: '',
        asset: '',
        concessionExpense: '',
        defaultExpense: '',
      },
      // b - modal

      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Income (Student Fee)',
          field: 'income_coa.gl_name',
        },
        {
          label: 'Asset (Receivable)',
          field: 'asset_coa.gl_name',
        },
        {
          label: 'Expense (Concessions)',
          field: 'expense_coa.names',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',

      incomeOptions: [],
      assetOptions: [],
      expenseOptions: [],

      incomeState: null,
      assetState: null,
      expenseState: null,

      isUpdate: false,
      updateId: '',

      tag: 'coa-mapping',
      activityModal: false,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {

  },
  created() {
  },
  mounted() {
    this.$root.$on('bv::modal::hide', () => {
      this.selectedModal = null
      this.glNameFilter = null
    })

    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (bvEvent.type === 'show' && modalId === 'coa-modal') this.resetForm()
    })
    /*
    * functions call in mount
    */
    this.getGridData()
    /*
    * functions call in mount
    */
  },
  methods: {

    /*
    * get data from apis
    */
    getIncome() {
      CoaMappingService.getIncome().then(res => {
        this.incomeOptions = res.data.data
      })
    },
    getAsset() {
      CoaMappingService.getAsset().then(res => {
        this.assetOptions = res.data.data
      })
    },
    getExpense() {
      CoaMappingService.getExpense().then(res => {
        this.expenseOptions = res.data.data
      })
    },
    getGridData() {
      CoaMappingService.getAll().then(res => {
        if (res.data.status) {
          this.rows = res.data.data
        }
      })
    },
    refreshGrid() {
      this.getGridData()
    },
    /*
    * get data from apis
    */
    validationFunc() {
      this.incomeState = null
      this.assetState = null
      this.expenseState = null
      if (this.coaMapping.income === '' || this.coaMapping.income == null) {
        this.incomeState = false
        return false
      }
      if (this.coaMapping.asset === '' || this.coaMapping.asset == null) {
        this.assetState = false
        return false
      }
      // if (this.coaMapping.concessionExpense === '' || this.coaMapping.concessionExpense == null) {
      //   this.expenseState = false
      //   return false
      // }
      return true
    },
    coaMappingModal() {
      this.resetForm()
      this.getIncome()
      this.getAsset()
      this.getExpense()
      this.$bvModal.show('coa-mapping-modal')
    },
    saveCoaMapping() {
      const params = {
        incomeCoaId: this.coaMapping.income,
        assetCoaId: this.coaMapping.asset,
        expenseCoaIds: this.coaMapping.concessionExpense && this.coaMapping.concessionExpense.length > 0 ? JSON.stringify(this.coaMapping.concessionExpense) : null,
        defaultExpenseCoaId: this.coaMapping.defaultExpense,
      }
      if (!this.validationFunc()) {
        return
      }
      if (this.isUpdate) {
        CoaMappingService.update(this.updateId, params).then(res => {
          if (res.data.status) {
            this.updateId = null
            this.isUpdate = false
            FemsToastrService.success(res?.message)
            this.$bvModal.hide('coa-mapping-modal')
            this.getGridData()
          } else {
            FemsToastrService.error(res?.message)
          }
        })
          .catch(err => {
            FemsToastrService.error(err)
          })
      } else {
        CoaMappingService.save(params).then(res => {
          if (res.data.status) {
            FemsToastrService.success(res?.message)
            this.$bvModal.hide('coa-mapping-modal')
            this.getGridData()
          } else {
            FemsToastrService.error(res?.message)
          }
        })
          .catch(err => {
            FemsToastrService.error(err)
          })
      }
    },

    editRow(rowData) {
      this.resetForm()
      this.getIncome()
      this.getAsset()
      this.getExpense()
      CoaMappingService.getOne(rowData.id).then(res => {
        this.isUpdate = true
        this.updateId = res.data.data.id
        this.coaMapping.income = res.data.data.income_coa_id
        this.coaMapping.asset = res.data.data.asset_coa_id
        this.coaMapping.concessionExpense = res.data.data.expense_coa_ids
        this.coaMapping.defaultExpense = res.data.data.default_expense_coa_id
      })
      this.$bvModal.show('coa-mapping-modal')
    },
    deleteRow() {

    },
    resetForm() {
      this.coaMapping = {
        income: '',
        asset: '',
        concessionExpense: '',
        defaultExpense: '',
      }

      this.searchTerm = ''

      this.isUpdate = false
      this.updateId = ''
      this.incomeState = null
      this.assetState = null
      this.expenseState = null
    },

    // Audit ACtivity
    onActivityLog() {
      this.activityModal = true
      this.$nextTick(() => {
        this.$refs.activityModal.modalShow()
      })
    },

  },
}
</script>
<style lang="scss">
svg.feather.feather-refresh-cw {
  height: 15px;
  width: 15px;
}
</style>
