import axiosIns from '@/libs/axios'

export default {

  getIncome: () => axiosIns.get('accounting/coa-mapping/std-fee-coa'),
  getAsset: () => axiosIns.get('accounting/coa-mapping/receivable-coa'),
  getExpense: () => axiosIns.get('accounting/coa-mapping/concession-coa'),

  getAll: () => axiosIns.get('accounting/std-coa-mapping'),
  save: params => axiosIns.post('accounting/std-coa-mapping', params),
  getOne: id => axiosIns.get(`accounting/std-coa-mapping/${id}`),
  update: (id, params) => axiosIns.put(`accounting/std-coa-mapping/${id}`, params),
  delete: id => axiosIns.delete(`accounting/std-coa-mapping/${id}`),

}
